import { createApp } from 'vue';
import App from './App.vue';
import router from './route';
import install from './install';
import { createPinia } from 'pinia';
import 'animate.css/animate.min.css';
import 'vant/lib/index.css';
import { Lazyload } from 'vant';
const pinia = createPinia();
const app = createApp(App);
app.use(Lazyload);
app.use(install);
app.use(router);
app.use(pinia);
app.mount('#app');

// 一下增加js统计代码

/**
 * 将字符串中的特殊字符转义为 HTML 实体
 * @param {string} str - 需要转义的字符串
 * @returns {string} - 转义后的字符串
 */
const unescapeHTML = (escapedString) => {
	var map = {
		'&lt;': '<',
		'&gt;': '>',
		'&amp;': '&',
		'&quot;': '"',
		'&#39;': "'"
	};

	return escapedString.replace(/&lt;|&gt;|&amp;|&quot;|&#39;/g, function (m) {
		return map[m];
	});
};
import { useIndexStore } from './store';
import { storeToRefs } from 'pinia';
import { windowHeight } from 'vant/lib/utils';
const userIndex = useIndexStore();
const { config } = storeToRefs(userIndex);
router.afterEach((to, from, next) => {
	loaddingCnzz();
	loaddingOpenShare();
});

const loaddingCnzz = () => {
	setTimeout(() => {
		if (config.value?.statistics_js && config.value?.statistics_js.length > 0) {
			let tj = unescapeHTML(config.value?.statistics_js);
			// console.log("tj", tj,typeof(tj));
			let newStr = tj.replace(/<script>/gi, '').replace(/<\/script>/gi, '');
			// alert(newStr)
			eval(newStr);
		}

		// alert(123)
		// 此处粘贴百度统计复制过来的代码
		//   var _hmt = _hmt || [];
		//   (function() {
		//     var hm = document.createElement("script");
		//     hm.src = "https://hm.baidu.com/hm.js?************";
		//     var s = document.getElementsByTagName("script")[0];
		//     s.parentNode.insertBefore(hm, s);
		// })();
	}, 2000);
};

const loaddingOpenShare = () => {
	// OpenShare 统计代码  https://159cloud.com/#/help
	var _hmt = _hmt || [];
	(function () {
		var hm = document.createElement('script');
		hm.src = 'https://os.sdwok.cn/open/os2.js';
		var s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(hm, s);

		if (window.openShare == undefined) {
			setTimeout(() => {
				loaddingOpenShare();
			}, 1000);
		} else {
			var m = new window.OpenShare({ appKey: 'xlF0SHRcVrbEfJv' });
			function DownSoft() {
				m.wakeupOrInstall;
			}
		}
	})();
};

const setViewportScale = () => {
	const pageWidth = window.innerWidth;
	const pageHeight = window.innerHeight;
	const targetWidth = 375;
	const isPC = pageWidth > 1000;
	const scale = isPC ? pageWidth / targetWidth : 0.3;

	console.log('scale', scale);

	const app = document.getElementById('app');
	if (app && isPC) {
		document.getElement;
		app.style.transform = `scale(${scale})`;
		app.style.width = `${targetWidth}px`;
		app.style.height = `${pageHeight}px`; // 你可以根据需要调整高度
	}
};
// setViewportScale();

function resizeContainer() {
	const container = document.getElementById('app');
	const scale = Math.min(window.innerWidth / 375, window.innerHeight / 667);
	console.log('scale', scale);

	container.style.transform = `scale(${scale})`;
	container.style.transformOrigin = `top center`;
	// document.body.style.height = window.innerHeight / scale + 'px';
	container.style.height = window.innerHeight / scale + 'px';
	//document.getElementById('tabbarList').style.display = 'none';
}

// window.addEventListener('resize', resizeContainer);
// window.addEventListener('load', resizeContainer);
